<template>
  <div class='conokk'>
    <div class='tixing'>
      <div class='tixingtem'>
        <div class='tem_mm'><img style='width: 60%' src='../../../assets/img/jiayou.svg' alt=''></div>
      <div class='texts'>
        <p>加油审核</p>
        <p>{{ this.count2[0] ||0}}</p>
      </div>
      </div>
      <div class='tixingtem'>
        <div class='tem_mm'><img style='width: 60%' src='../../../assets/img/baox.svg' alt=''></div>
        <div class='texts'>
          <p>保险到期</p>
          <p>{{ this.count[0] ||0}}</p>
        </div>
      </div>
      <div class='tixingtem'>
        <div class='tem_mm'><img style='width: 60%' src='../../../assets/img/niajian.svg' alt=''></div>
        <div class='texts'>
          <p>年检到期</p>
          <p>{{ this.count[1] ||0}}</p>
        </div>
      </div>
    </div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
import { GetDaiban, GetYujingt } from '@/api/car'

export default {
  components: {

  },
  data() {
    return {
      count: [],
      count2: []
    }
  },
  mounted() {
    this.getdata()
  },
  methods: {
    async getdata() {
      const { data: res } = await GetYujingt()
      const { data: res2 } = await GetDaiban()
      this.count = res[2].count
      this.count2 = res2[2].count
      console.log(this.count)
      console.log(this.count2)
    }
  }
}
</script>
<style lang='less'>
.conokk{
  width: 100%;
  height: 100%;
  background: #edeff2;
}
.layout_home {
  height: 100%;

}
.tixing{
  height: 150px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  box-sizing: border-box;
  .tixingtem{
    width: 450px;
    height: 150px;
    margin: 20px;
    background: white;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px 20px 20px;
    box-sizing: border-box;
    .texts{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
    p{
      margin: 0;
      font-weight: bold;
      line-height: 18px;
      font-size: 20px;
      margin-bottom: 12px;
    }
  }
}
</style>
